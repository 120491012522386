<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}{{billName}}</strong>
                                    <el-checkbox v-if="isMobile" style="padding-left: 25px" v-model="showFrom">显示表单</el-checkbox>
                                    <el-select style="margin-left: 8px;width: 80px" v-model="warehouseBackOrGo.name" no-data-text="没有搜索结果!" placeholder="全部"
                                               default-first-option size="mini"
                                               @change="warehouseBackOrGo.id = $event.id,warehouseBackOrGo.name = $event.name"
                                               @focus="$_searcWarehouseList" clearable filterable>
                                        <el-option v-for="item in this.$store.getters.getWarehouseList" :key="item.id" :label="item.name"
                                                   :value="item">
                                            <span style="margin:15px;">{{ item.name}}</span>
                                        </el-option>
                                    </el-select>
                                    <el-button-group style="margin-left: 8px">
                                        <el-button size="mini" type="success" title="上一页" @click="$_BackOrGo(formData.id,formData.bill_id_type,warehouseBackOrGo,'Back')" icon="el-icon-caret-left"></el-button>
                                        <el-button size="mini" type="success" title="下一页" @click="$_BackOrGo(formData.id,formData.bill_id_type,warehouseBackOrGo,'Go')" icon="el-icon-caret-right"></el-button>
                                    </el-button-group>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}{{titleList}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <div v-show="showFrom">
                            <el-row>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="开单日期:">
                                        <el-date-picker
                                                v-model="formData.only_bill_date"
                                                align="right"
                                                type="date"
                                                size="mini"
                                                :clearable="false"
                                                :disabled="titleListSize > 0"
                                                style="width: 120px"
                                                :readonly="false">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12" :md="5">
                                    <el-tooltip class="item" effect="dark"
                                                :content="`单号唯一ID:${formData.id ? formData.id : '自动生成'}`" placement="top"
                                                :open-delay="750">
                                        <el-form-item label="销售开单-单号:" label-width="130px">
                                            <el-input v-model="formData.bill_id_kd" :readonly="true" placeholder="自动生成"
                                                      @keyup.native.enter="$_focusNext('registrar')"
                                                      size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            修改人:{{formData.last_name_warehouse_bill ? formData.last_name_warehouse_bill : ''}}<br>
                                            修改时间:{{formData.last_date_warehouse_bill ? formData.last_date_warehouse_bill : ''}}<br>
                                            创建时间:{{formData.creat_date_warehouse_bill ? formData.creat_date_warehouse_bill : ''}}
                                        </div>
                                        <el-form-item label="记录员:">
                                            <el-input ref="registrar" v-model="formData.registrar" :readonly="true"
                                                      size="mini"
                                                      @keyup.native.enter="$_focusNext('last_date_warehouse_bill')"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :lg="4" :sm="12" :md="8" v-show="false">
                                    <el-form-item label="修改时间:">
                                        <el-input ref="last_date_warehouse_bill" v-model="formData.last_date_warehouse_bill"
                                                  placeholder="自动生成" :readonly="true" size="mini"
                                                  @keyup.native.enter="$_focusNext('last_name_warehouse_bill')"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8" v-show="false">
                                    <el-form-item label="修改人:">
                                        <el-input ref="last_name_warehouse_bill" v-model="formData.last_name_warehouse_bill"
                                                  placeholder="自动生成" :readonly="true" size="mini"
                                                  @keyup.native.enter="$_focusNext('customer')"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" content="最后保存的用户" placement="top-end"
                                                :open-delay="750">
                                        <el-form-item label="开单员:">
                                            <el-input ref="part_order" placeholder="自动修改" v-model="formData.part_order"
                                                      :readonly="true" size="mini"
                                                      @keyup.native.enter="$_blurNext('part_order'),$refs.xGrid.setActiveCell(tableDataMain[0],'specification')"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :sm="12" :md="5">
                                    <el-form-item label="开单通知书-单号:" label-width="130px">
                                        <el-input ref="bill_id_kdtzs" v-model="formData.bill_id_kdtzs"  :readonly="true"
                                                  placeholder="未关联"
                                                  size="mini">
                                            <el-button v-show="!this.isBlankVue(formData.bill_id_kdtzs)" slot="append" style="margin: initial;padding: initial;" @click="$_createBillByTransferSlipInform()"  icon="el-icon-position"></el-button>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12" :md="5">
                                    <el-form-item label="发货单-单号:" label-width="110px">
                                        <el-input ref="bill_id_fh" v-model="formData.bill_id_fh" :readonly="true"
                                                  placeholder="自动生成"
                                                  size="mini">
                                            <el-button v-show="!this.isBlankVue(formData.bill_id_fh)" slot="append" style="margin: initial;padding: initial;" @click="$_createBillByPrintShippingAdvice('KD')"  icon="el-icon-position"></el-button>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">公司ID:{{formData.company_uuid}}<br/>客户:{{formData.customer}}<br/>账期:{{formData.payment_days?formData.payment_days:0}}天</div>
                                        <el-form-item label="客户:">
                                            <!--disabled 删除了：formData.total_amount != formData.balance ||-->
                                            <el-select ref="customer" v-model="formData.customer" style="width: 100%;"
                                                       @keyup.native.enter="$_blurNext('customer'),$_focusNext('sales_man')"
                                                       default-first-option remote :remote-method="$_searchCustomerList"
                                                       placeholder="可搜索查询"
                                                       @change="isDataChanage = true,customerChangeEvent($event)"
                                                       :disabled="titleListSize > 0"
                                                       clearable
                                                       @visible-change="(v) => $refs.AddCompany.visibleChange(v, this.$refs['customer'])"
                                                       filterable size="mini">
                                                <el-option
                                                        v-for="item in $store.getters.getCustomerList"
                                                        :key="item.id"
                                                        :label="item.customer_name"
                                                        :value="item">
                                                    <span style="float: left">{{item.customer_name}}</span>
                                                    <span v-if="$_getActionKeyListBoolean(['/customers/editVue'])"
                                                          style="float: right"><i
                                                            class="el-icon-edit-outline__close el-icon-edit-outline"
                                                            @click.stop="$_blurNext('customer'),customerChangeEvent(item),$refs.AddCompany.editRowEvent(item.id)"/></span>
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <AddCompany ref="AddCompany" :page-type="pageType"></AddCompany>
                                <el-col :md="3">
                                    <el-form-item label="业务员:">
                                        <el-select ref="sales_man" v-model="formData.sales_man" clearable placeholder="可搜索查询"
                                                   size="mini"
                                                   @change="isDataChanage = true"
                                                   @focus="$_salesManListFilter"
                                                   :filter-method="$_salesManListFilter"
                                                   default-first-option
                                                   @keyup.native.enter="$_blurNext('sales_man'),$_focusNext('settlement_days')"
                                                   filterable>
                                            <el-option
                                                    v-for="item in $store.getters.getSalesManList"
                                                    :key="item.nick_name"
                                                    :label="item.nick_name"
                                                    :value="item.nick_name">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="2" :sm="12" :md="8">
                                    <el-form-item label="结算天数:" label-width="80px">
                                        <vxe-input :controls="false" style="width: 50px" ref="settlement_days"
                                                   type="number"
                                                   v-model="formData.settlement_days"
                                                   @change="isDataChanage = true"
                                                   placeholder="" size="mini"
                                                   :disabled="titleListSize > 0"
                                                   @keyup.native.enter="$_focusNext('clearing_form')">
                                        </vxe-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="结算方式:">
                                        <!--@keyup.native.enter="$_blurNext('clearing_form'),$_focusNext('otherExpenses')"-->
                                        <el-select style="width: 90px" ref="clearing_form" v-model="formData.clearing_form"
                                                   placeholder="可搜索查询"
                                                   size="mini" clearable default-first-option
                                                   @change="isDataChanage = true"
                                                   :disabled="titleListSize > 0"
                                                   @keyup.native.enter="$_blurNext('clearing_form'),$_focusNext('tax_tate_warehouse_bill')"
                                                   filterable remote :remote-method="$_getClearingFormList">
                                            <el-option
                                                    v-for="item in $store.getters.getClearingFormList"
                                                    :key="item.pay_name"
                                                    :label="item.pay_name"
                                                    :value="item.pay_name">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="2" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            《开单通知书》、《销售订单》生成《销售开单》时：<br/>税率读取《税率设置》模块对应的真实税率 进行转换赋值<br/>
                                        </div>
                                        <el-form-item label="税率:" label-width="20" style="margin-left: 5px">
                                            <vxe-input style="width: 70px" ref="tax_tate_warehouse_bill"
                                                       v-model="formData.tax_tate_warehouse_bill"
                                                       placeholder="" size="mini"
                                                       :disabled="titleListSize > 0"
                                                       @change="$_setTaxTateStockRow(),$_taxTateWarehouseBillEvent(),$_singleCountEventEvery(),isDataChanage = true"
                                                       @keyup.native.enter="$_focusNext('taxes_fees_second_string')">
                                                <template #suffix>
                                                    <span>%</span>
                                                </template>
                                            </vxe-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="后补税金:">
                                        <vxe-input style="width: 110px;" :controls="false" :precision="2" size="mini"
                                                   type="float" ref="taxes_fees_second_string"
                                                   clearable
                                                   :disabled="titleListSize > 0"
                                                   v-model="formData.taxes_fees_second_string"
                                                   @change="isDataChanage = true,taxesFeesSecondStringEvent(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$_focusNext('remark_warehouse_bill')"></vxe-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12" :md="4">
                                    <el-tooltip class="item" effect="dark" placement="top-end" :open-delay="750">
                                        <div slot="content">
                                            《开单通知书》、《销售订单》生成《销售开单》时：<br/>
                                            《开单通知书》、《销售订单》的税率 赋值 《销售开单》的销售备注<br>打印说明：含税才会打印销售备注
                                        </div>
                                        <el-form-item label="销售备注 :">
                                            <el-input ref="remark_warehouse_bill" v-model="formData.remark_warehouse_bill"
                                                      @change="checkRemarkWarehouseBill(),isDataChanage = true"
                                                      clearable
                                                      @keyup.native.enter="$_focusNext('handled_by')"
                                                      placeholder="请输入" size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :sm="12" :md="4" v-show="false">
                                    <el-form-item label="开单备注 :">
                                        <el-input ref="open_bill_remark" v-model="formData.open_bill_remark"
                                                  @change="isDataChanage = true"
                                                  :disabled="true"
                                                  clearable
                                                  @keyup.native.enter="$_focusNext('handled_by')"
                                                  placeholder="请输入" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="经手人:">
                                        <el-select ref="handled_by" v-model="formData.handled_by" clearable
                                                   placeholder="可搜索查询"
                                                   size="mini"
                                                   default-first-option
                                                   :filter-method="$_handledByListFilter"
                                                   @change="isDataChanage = true,handledByEvent()"
                                                   @keyup.native.enter="$_blurNext('handled_by'),$_focusNext('billing_company_name')"
                                                   filterable>
                                            <el-option
                                                    v-for="item in $store.getters.getHandledByList"
                                                    :key="item.nick_name"
                                                    :label="item.nick_name"
                                                    :value="item.nick_name">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>


                                <!--  <el-col :lg="3" :sm="12" :md="8">
                                      <el-form-item label="账户资金:">
                                          <el-input size="mini" @change="isDataChanage = true"></el-input>
                                      </el-form-item>
                                  </el-col>
                                  <el-col :lg="3" :sm="12" :md="8">
                                      <el-form-item label="本次结余:">
                                          <el-input size="mini" @change="isDataChanage = true"></el-input>
                                      </el-form-item>
                                  </el-col>-->
                            </el-row>
                            <el-row>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="开单公司:">
                                        <el-select ref="billing_company_name" v-model="formData.billing_company_name"
                                                   @change="isDataChanage = true,formData.billing_company_id = $event.id,formData.billing_company_name = $event.name"
                                                   placeholder="可搜索查询"
                                                   @keyup.native.enter="$_blurNext('billing_company_name'),$_focusNext('pounds_fees')"
                                                   style="width: 100%"
                                                   :disabled="formData.total_amount != formData.balance && titleListSize > 0"
                                                   default-first-option remote
                                                   :remote-method="$_getBillingCompanySettingList"
                                                   clearable
                                                   filterable size="mini">
                                            <el-option
                                                    v-for="item in $store.getters.getBillingCompanyList"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="1">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            勾选时，打印模板“不会”显示相关费用<br/>
                                            勾选时，合计金额“不会“加(磅费、风割费、装车费、中转费、运费、税金)<br/>
                                            不勾选时(代收代付)，打印模板“会“显示相关费用<br/>
                                            不勾选时(代收代付)，合计金额“会“加(磅费、风割费、装车费、中转费、运费、税金)<br/>
                                        </div>
                                        <div style="margin-top: 10px;margin-left: 15px">
                                            <!--true-label全面有冒号:则值是数字，没有:则选中时的值是字符串1）-->
                                            <el-checkbox ref="is_contract_fees" :true-label="1" :false-label="0"
                                                         v-model="formData.is_contract_fees"
                                                         :disabled="isEdit"
                                                         @change="isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()">
                                                包费用
                                            </el-checkbox>
                                        </div>
                                    </el-tooltip>
                                </el-col>
                                <el-col :sm="12" :md="2">
                                    <el-form-item label="磅费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2" size="mini"
                                                   type="float" ref="pounds_fees" v-model="formData.pounds_fees"
                                                   :disabled="isEdit"
                                                   clearable
                                                   @change="formData.pounds_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$_focusNext('wind_cut_fees')"></vxe-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :sm="12" :md="2">
                                    <el-form-item label="风割费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2" size="mini"
                                                   type="float" ref="wind_cut_fees" v-model="formData.wind_cut_fees"
                                                   :disabled="isEdit"
                                                   clearable
                                                   @change="formData.wind_cut_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$_focusNext('loading_car_fees')"></vxe-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :sm="12" :md="2">
                                    <el-form-item label="装车费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2" size="mini"
                                                   type="float" ref="loading_car_fees"
                                                   v-model="formData.loading_car_fees"
                                                   :disabled="isEdit"
                                                   clearable
                                                   @change="formData.loading_car_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$_focusNext('transfer_fees')"></vxe-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :sm="12" :md="2">
                                    <el-form-item label="中转费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2" size="mini"
                                                   type="float" ref="transfer_fees" v-model="formData.transfer_fees"
                                                   :disabled="isEdit"
                                                   clearable
                                                   @change="formData.transfer_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$_focusNext('freight_fees')"></vxe-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12" :md="2">
                                    <el-form-item label="运费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2" size="mini"
                                                   type="float" ref="freight_fees" v-model="formData.freight_fees"
                                                   :disabled="isEdit"
                                                   clearable
                                                   @change="formData.freight_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$_focusNext('procedure_fees')"></vxe-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12" :md="2">
                                    <el-form-item label="手续费:">
                                        <vxe-input style="width: 60px;" :controls="false" :precision="2" size="mini"
                                                   type="float" ref="procedure_fees" v-model="formData.procedure_fees"
                                                   :disabled="isEdit"
                                                   clearable
                                                   @change="formData.procedure_fees_diy = false,isDataChanage = true,$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery()"
                                                   @keyup.native.enter="$_focusNext('other_tax')"></vxe-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12" :md="3">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            杂费：磅费、风割费、装车费、中转费、运费<br/>
                                        </div>
                                        <el-form-item label="杂费税率:" label-width="100px">
                                            <vxe-input ref="other_tax" style="width: 70px"
                                                       v-model="formData.other_tax"
                                                       :disabled="titleListSize > 0"
                                                       type="number" size="mini"
                                                       @change="$_taxTateWarehouseBillEvent(),$_sumAllFees(),$_setOtherTaxMoney(),$_getFeesEveryRow(),$_singleCountEventEvery(),isDataChanage = true"
                                                       @keyup.native.enter="$refs.xGrid.setActiveCell(tableDataMain[0],'specification')">
                                                <template #suffix>
                                                    <span>%</span>
                                                </template>
                                            </vxe-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>


                            </el-row>
                            <el-row>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            合计杂费 = 磅费 + 风割费 + 装车费 + 中转费 + 运费 + 手续费
                                        </div>
                                        <el-form-item label="合计杂费:">
                                            <el-input :readonly="true" placeholder="自动计算"
                                                      :value="this.$XEUtils.commafy(Number(formData.all_fees), {digits: 2})"
                                                      size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <!-- <el-col :lg="3" :sm="12" :md="8">
                                     <el-form-item label="合同编号 :">
                                         <el-input size="mini" ref="contractId" v-model="formData.contractId"
                                                   @change="isDataChanage = true"
                                                   @keyup.native.enter="$_focusNext('clearing_form')"></el-input>
                                     </el-form-item>
                                 </el-col>-->

                                <el-col :sm="12" :md="3">
                                    <el-form-item label="合计支数:">
                                        <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_number"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="总过磅重:">
                                        <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_weighing_heavy"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="总理论重:">
                                        <el-input :readonly="true" placeholder="自动计算"
                                                  v-model="formData.total_theoretical_weight" size="mini"></el-input>
                                    </el-form-item>
                                </el-col>


                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            出纳：应收账款
                                        </div>
                                        <el-form-item label="合计金额:">
                                            <el-input :readonly="true" placeholder="自动计算"
                                                      :value="this.$XEUtils.commafy(Number(formData.total_amount), {digits: 2})"
                                                      size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            合计实收 = 每行实收金额累加
                                        </div>
                                        <el-form-item label="合计实收:">
                                            <el-input :readonly="true" placeholder="自动计算"
                                                      :value="this.$XEUtils.commafy(Number(formData.practical_total_price), {digits: 2})"
                                                      size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-form-item label="合计佣金:">
                                        <el-input :readonly="true" placeholder="自动计算"
                                                  :value="this.$XEUtils.commafy(Number(formData.kick_back_total), {digits: 2})"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            合计税金 = 杂费税金 + 产品税金
                                        </div>
                                        <el-form-item label="合计税金:">
                                            <el-input :readonly="true" placeholder="自动计算"
                                                      :value="this.$XEUtils.commafy(Number(formData.taxes_fees), {digits: 2})"
                                                      size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>


                            </el-row>
                            <el-row>
                                <el-col :lg="3" :sm="12" :md="8" v-show="false">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            杂费税金 = (磅费 + 风割费 + 装车费 + 中转费 + 运费) ÷ (1 + 杂费税率) × 杂费税率
                                        </div>
                                        <el-form-item label="杂费税金:">
                                            <el-input :readonly="true" placeholder="自动计算"
                                                      :value="this.$XEUtils.commafy(Number(formData.other_tax_money), {digits: 2})"
                                                      size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :lg="3" :sm="12" :md="8" v-show="false">
                                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                        <div slot="content">
                                            财务：商品销售收入
                                        </div>
                                        <el-form-item label="合计裸价:">
                                            <el-input :readonly="true" placeholder="自动计算"
                                                      :value="this.$XEUtils.commafy(Number(formData.naked_price_total), {digits: 2})"
                                                      size="mini"></el-input>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>

                                <el-col :sm="12" :md="5">
                                    <el-form-item label="销售订单-单号:" label-width="110px">
                                        <el-input ref="bill_id_dd" v-model="formData.bill_id_dd" :readonly="true"
                                                  placeholder="自动生成"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12" :md="17">
                                    <el-button style=" margin-top: 12px;margin-right:8px;" type="success"
                                               round @click="$router.push('/transferSlip')" size="mini">新建
                                    </el-button>
                                    <vxe-toolbar class="pull-left" style="margin-left:8px; margin-right:8px; height: 42px; "
                                                 custom export
                                                 ref="xToolbar2">
                                    </vxe-toolbar>

                                    <el-button style=" margin-top: 12px;" type="primary" round
                                               @click="PrintTransferSlip" size="mini">打印预览
                                    </el-button>


                                    <el-button type="primary" size="mini" @click="$router.back()"
                                               style="margin-top: 12px">返回
                                    </el-button>
                                    <!--取消 销售开单 生产 发货单-->
                                    <el-button style=" margin-top: 12px;" type="primary" round v-if="$_getActionKeyListBoolean(['/transferSlip/createBillByPrintShippingAdvice'])"
                                               :disabled="this.formData.is_cancel"
                                               @click="$_createBillByPrintShippingAdvice('KD')" size="mini">
                                        {{formData.bill_id_fh ==
                                        '' || formData.bill_id_fh == null? '生成':'查看'}}《发货单》
                                    </el-button>

                                    <el-button style=" margin-top: 12px; " type="danger" plain round
                                               :disabled="this.loading || this.formData.is_cancel"
                                               @click="onSubmit" size="mini">保存
                                    </el-button>

                                    <el-button v-if="$_getActionKeyListBoolean(['/transferSlip/outBatch'])"
                                               style=" margin-top: 12px; " type="primary" plain round
                                               @click="outBatch(true)" size="mini">出仓一键审核
                                    </el-button>
                                    <el-button v-if="$_getActionKeyListBoolean(['/transferSlip/outBatch'])"
                                               style=" margin-top: 12px; " type="primary" plain round
                                               @click="outBatch(false)" size="mini">出仓一键反审
                                    </el-button>
                                    <el-button v-if="this.isBlankVue(formData.voucher_id)"
                                               class="pull-right" style=" margin-top: 12px; " type="warning" plain round
                                               :disabled="!this.isBlankVue(this.formData.voucher_id)"
                                               @click="$_openType({path: '/allWarehouse/allBilling', query: {pickerDate:[$_formatDate($getMonthFirst(formData.only_bill_date), 'yyyy-MM-dd'),$_formatDate($getMonthLast(formData.only_bill_date), 'yyyy-MM-dd')],customer:formData.customer,billCategory:'KD'}})"
                                               size="mini">生成凭证
                                    </el-button>
                                    <el-button v-if="!this.isBlankVue(formData.voucher_id)"
                                               class="pull-right" style=" margin-top: 12px; " type="warning" plain round
                                               :disabled="this.isBlankVue(this.formData.voucher_id)"
                                               @click="$_openType({path: '/voucher', query: {id : formData.voucher_id}})"
                                               size="mini">查看凭证
                                    </el-button>
                                    <el-button class="pull-right" style=" margin-top: 12px; " type="info" plain round
                                               @click="hideColumnEvent()" size="mini">财务精简列
                                    </el-button>
                                    <el-button class="pull-right" style=" margin-top: 12px; " type="info" plain round
                                               @click="$refs.xGrid.$refs.baseGird.resetColumn()" size="mini">重置列
                                    </el-button>

                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <MnGrid ref="xGrid" id="TransferSlipId" :height="(this.getViewHeight() - 290)+''"
                                :table-column="tableColumn"
                                :searchWarehouseBill="searchWarehouseBill"
                                :saveOrUpdate="saveOrUpdate"
                                search-type="transferSlip"
                                :titleList="titleList"
                                :table-data-main="tableDataMain" :form-data="formData" :allow-create="false"
                                :search-all="true" :is-edit="!isEdit"></MnGrid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <vxe-modal title="提示" v-model="isShowCompanyModel"
                   :esc-closable="false"
                   :mask-closable="true"
                   :lock-view="true"
                   :mask="false"
                   :resize="false"
                   :draggable="false">
            <el-alert
                    :title="customersErrorMsg"
                    type="error"
                    :closable="false">
            </el-alert>
            <el-button class="pull-right" style=" margin-top: 12px; " type="success" plain round
                       @click="$refs.AddCompany.editRowEvent(customersFormId),isShowCompanyModel = false" size="mini">进行编辑
            </el-button>
            <el-button class="pull-right" style=" margin-top: 12px; " type="info" plain round
                       @click="isShowCompanyModel = false" size="mini">取消关闭
            </el-button>
        </vxe-modal>
    </div>
</template>

<script>

    import MnGrid from "../../components/MnGrid";
    import AddCompany from "../../components/AddCompany";

    export default {
        // eslint-disable-next-line vue/no-unused-components
        components: {AddCompany, MnGrid},

        data() {
            return {
                isShowCompanyModel:false,
                customersFormId:'',//编辑公式信息的id
                customersErrorMsg:'客户资料需要补充完整，可点击进行编辑！',//公式img
                pageType:'销售开单',
                warehouseBackOrGo:{
                    id:'',
                    name:'',
                },//仓库查询 上一张下一张
                filterData: {
                    customer_name:'',
                    state:"激活"
               },
                isEditFees:true,
                titleList: [],
                titleListSize: 0,
                isEdit: false,
                start_date: '',
                isDataChanage: false,
                tableColumn: [
                    {type: 'seq', title: '序号',fixed: 'left', className: 'unEditor', width: 40},
                    {
                        field:'warehouse_out',
                        fixed: 'left',
                        width: 65,
                        title: '调出仓库',
                        slots: {edit: 'warehouse_out_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'specification',
                        fixed: 'left',
                        width: 150, title: '实际规格',
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },

                    {
                        field:'category',
                        fixed: 'left',
                        width: 50,
                        title: '类别',
                        slots: {edit: 'category_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'unit',
                        fixed: 'left',
                        width: 45,
                        title: '单位',
                        slots: {edit: 'unit_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'single_count', width: 55, title: '支数',
                        fixed: 'left',
                        slots: {edit: 'single_count_autocomplete_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'full_thick',
                        width: 50,
                        title: '足厚',
                        slots: {edit: 'full_thick_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    /*{
                        field:'single_count', width: 45, title: '支数', editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            titleHelp: {message: '过磅重=理论支重*支数\n可以自行修改\n(吨)'},
                            events: {change: this.singleCountEvent}
                       }
                   },*/
                    {
                        field:'price', width: 60, title: '单价',
                        titleHelp: {message: '单位(吨) 单价 = 吨价\n单位(支) 单价 = 每支单价'},
                        slots: {edit: 'price_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    /*{
                        field:'price', width: 60, title: '单价',
                        titleHelp: {message: '单位(吨) 单价= 吨价\n单位(支) 单价= 每支单价'},
                        editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.$_setPrice}
                       }
                   },*/
                    {
                        field:'weighing_heavy',
                        width: 80,
                        title: '过磅重(吨)',
                        titleHelp: {message: '过磅重=理论支重 * 支数\n可以自行修改\n(吨)'},
                        slots: {edit: 'weighing_heavy_edit'},
                        editRender: {autofocus: 'input.el-input__inner',enabled:true}
                   },
                    {
                        field:'theoretical_weight',
                        width: 80,
                        className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量'
                   },
                    /*{
                        field:'weighing_heavy',
                        width: 65,
                        titleHelp: {message: '过磅重=理论支重*支数\n可以自行修改\n(吨)'},
                        title: '过磅重',
                        editRender: {
                            name: 'ElInputNumber',
                            props: {controls: false},
                            events: {change: this.weighingHeavyEvent}
                       }
                   },*/
                    {
                        field:'amount_of_product',
                        width: 90,
                        title: '产品金额', slots: {edit: 'amount_of_product_edit'},
                        editRender: {autofocus: 'input.el-input__inner'},
                        titleHelp: {message: '单位(吨) 产品金额 = 过磅重 * 吨价\n单位(支) 产品金额 = 支数 * 每支单价'}
                   },
                    {
                        field:'single_price_practical', width: 80, title: '实收单价',
                        slots: {edit: 'single_price_practical_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },

                    {
                        field:'practical_price',
                        width: 90,
                        title: '实收金额',
                        slots: {edit: 'practical_price_edit'},
                        editRender: {autofocus: 'input.el-input__inner'},
                        titleHelp: {message: '单位(吨) 实收金额=实收单价 * 过磅重\n单位(支) 实收金额 = 实收单价 * 支数'},
                   },
                   {
                        field:'actual_thickness', width: 45, title: '实厚',
                        slots: {edit: 'actual_thickness_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'polish_roll', width: 100, title: '打磨驳口',
                        slots: {edit: 'polish_roll_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field:'tax_tate_stock',
                        title: '产品税率',
                        width: 50,
                        slots: {edit: 'tax_tate_stock_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    /*{
                        field:'actual_thickness', width: 45,
                        title: '实厚',
                        editRender: {name: 'ElInputNumber', props: {controls: false}}
                   },*/
                    {
                        field:'tons_of_price_practical', width: 70, className: 'unEditor', title: '实际吨价',
                        titleHelp: {message: '(不含税吨价)\n实际吨价=产品裸价 ÷ 过磅重(吨)\n实际每吨价格'}
                    },
                    {
                        field:'profit_unit', width: 70, className: 'unEditor', title: '每吨毛利',
                        titleHelp: {message: '每吨毛利 = 单位毛利(每天价格表的毛利计算)\n注意：保存后才会显示\n（订货）单位毛利 = 实际吨价(不含税) - 成本吨价 - 费用单价\n（非订货）单位毛利 = 实际吨价(不含税) - 每日成本吨价 - 费用单价'}
                    },
                    {
                        field:'stock_bill_date_dd',
                        title: '订单日期',
                        width: 170,
                        slots: {edit: 'stock_bill_date_dd_edit'},
                        editRender: {autofocus: 'input.el-input__inner' /*,enabled:true*/},
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd HH:mm:ss')
                       }
                   },
                    {
                        field: 'theory_weight',
                        width: 50,
                        title: '理计',
                        slots: {edit: 'theory_weight_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field:'remark_stock',
                        width: 120,
                        title: '产品备注',
                        slots: {edit: 'remark_stock_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                    {
                        field:'kdcc_state_man', width: 60, title: '出仓审核'
                   }, {
                        field:'warehouse_out_id', visible: false,
                        width: 65,
                        className: 'unEditor',
                        title: '调出仓库Id'
                   },
                   {
                        field:'dd_stock_id', visible: true,
                        width: 85,
                        title: '销售订单规格ID'
                   },
                    {
                        field:'batch_number', visible: true,
                        width: 98,
                        className: 'unEditor',
                        title: '批号'
                    },
                    {field:'total_count', width: 50, className: 'unEditor', title: '库存'},
                    {field:'warehouse4', width: 50, className: 'unEditor', title: '厂一'},
                    {field:'warehouse5', width: 50, className: 'unEditor', title: '厂二'},
                    {field:'warehouse1', width: 50, className: 'unEditor', title: '仓一'},
                    {field:'warehouse2', width: 50, className: 'unEditor', title: '仓二'},
                    {field:'warehouse3', width: 50, className: 'unEditor', title: '仓三'},
                    {field:'warehouse9', width: 50, className: 'unEditor', title: '仓四'},
                    {field:'warehouse7', width: 50, className: 'unEditor', title: '新门市'},
                    {
                        field:'single_weight',
                        width: 50,
                        className: 'unEditor',
                        titleHelp: {message: '支重=过磅重 ÷ 支数\n(kg)'},
                        title: '支重'
                   },
                    {
                        field:'theoretical_weight_single',
                        width: 70, className: 'unEditor',
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(吨)'},
                        title: '理论支重'
                   }, {
                        field:'pounds_fees',
                        width: 50,
                        slots: {edit: 'pounds_fees_edit'},
                        editRender: {autofocus: 'input.el-input__inner'},
                        titleHelp: {message: '(当前行)磅费 = 合计磅费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品\n例如\n总金额：100元\n有4个规格根据重量平均下去：\n20.55元\n36.99元\n19.99元\n22.49元\n因为四舍五入，四个数加起来：100.02元多了2分，则自动拿第一个规格22.49元减去2分钱。'},
                        title: '磅费',
                   }, {
                        field:'wind_cut_fees',
                        width: 55, titleHelp: {message: '(当前行)风割费 = 合计风割费 ÷ 总过磅重 * (当前行)风割费\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'wind_cut_fees_edit'},
                        title: '风割费', editRender: {autofocus: 'input.el-input__inner'}
                   }, {
                        field:'loading_car_fees',
                        width: 55, titleHelp: {message: '(当前行)装车费 = 合计装车费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'loading_car_fees_edit'},
                        title: '装车费', editRender: {autofocus: 'input.el-input__inner\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                   }, {
                        field:'transfer_fees',
                        width: 55, titleHelp: {message: '(当前行)中转费 = 合计中转费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'transfer_fees_edit'},
                        title: '中转费', editRender: {autofocus: 'input.el-input__inner'}
                   }, {
                        field:'freight_fees',
                        width: 50, titleHelp: {message: '(当前行)运费 = 合计运费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'freight_fees_edit'},
                        title: '运费', editRender: {autofocus: 'input.el-input__inner'}
                   }, {
                        field:'procedure_fees',
                        width: 55, titleHelp: {message: '(当前行)手续费 = 合计手续费 ÷ 总过磅重 * (当前行)过磅重\n四舍五入保留两位小数，差值自动调整(加减)到第一个商品'},
                        slots: {edit: 'procedure_fees_edit'},
                        title: '手续费', editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field:'kick_back',
                        width: 50,
                        title: '佣金',
                        className: 'unEditor',
                        titleHelp: {message: '佣金=产品金额 - 实收金额'},
                   }, {
                        field:'actual_single_price',
                        width: 100,
                        title: '实际销售单价', className: 'unEditor',
                        titleHelp: {message: '单位是"支":实际销售单价=实际销售金额/支数\n单位是"吨":实际销售单价=实际销售金额/过磅重'}
                   },
                    {
                        field:'actual_a_price',
                        width: 100,
                        title: '实际销售支价', className: 'unEditor',
                        titleHelp: {message: '实际销售支价=实际销售金额 ÷ 支数'}
                    },
                    {
                        field:'actual_ton_price',
                        width: 100,
                        title: '实际销售吨价', className: 'unEditor',
                        titleHelp: {message: '实际销售吨价=实际销售金额 ÷ 过磅重'}
                    },
                    {
                        field:'actual_sales_amount',
                        width: 88,
                        title: '实际销售金额', className: 'unEditor',
                        titleHelp: {message: '实际销售金额'}
                   },{
                        field:'naked_price',
                        width: 70,
                        title: '产品裸价', className: 'unEditor',
                        titleHelp: {message: '(包费用)产品裸价 = (产品金额 - 佣金  - 各类费用) ÷ (1 + 产品税率)\n(不包费用)产品裸价 = (产品金额 - 佣金) ÷ (1 + 产品税率)'}
                   },

                    {
                        field:'taxes_fees', width: 70, className: 'unEditor', title: '产品税金',
                        titleHelp: {message: '产品税金 = 产品裸价 * 税率'}
                        /*titleHelp: {message: '情况1：税费 = 产品金额 * 税率\n情况2：税费 = 后补税费 ÷ 总过磅重 * (当前行)过磅重'}*/
                   }, {
                        field:'single_price', width: 65, title: '每支单价', className: 'unEditor',
                        titleHelp: {message: '(含税单价)\n(单位:支) 每支单价=单价\n(单位:吨) 每支单价 = 产品金额 ÷ 支数'}
                   },
                   {
                        field:'tons_of_price', width: 60, className: 'unEditor', title: '吨价',
                        titleHelp: {message: '(含税吨价)\n(单位:支)吨价 = 产品金额 ÷ 过磅重\n(单位:吨)吨价 = 单价'}
                   },
                    {
                        field:'actual_a_price_no_tax', width: 140, className: 'unEditor', title: '实际销售支价(不含税)',
                        titleHelp: {message: '实际销售支价(不含税) = 产品裸价 / 支数'}
                    },
                    {field:'operate',title: '操作',fixed:'right' , width: 35, slots: {default: 'operate'}},
                ],
                tableDataMain: [],
                specification: [],
                categoryList: [],
                unitList: [],
                accountList: [],
                warehouseList: [],
                activeName: 'first',
                checked: false,
                tableData: [],
                regionList: [],
                loading: false,
                formData: {
                    id: '',
                    has_invoice: '',
                    open_bill_remark: '',
                    recordInventory: {
                        end_date: '',
                        id: '',
                        is_over: '',
                        start_date: '',
                        year_month: ''
                   },
                    invoice_id: '',
                    bill_id_type: 'KD',
                    part_order : '',
                    clearing_form: '欠款',
                    other_expenses : '运费',//弃用
                    other_expenses_price : '',//弃用
                    customer_phone: '',
                    car_code : '',
                    bill_date: '',
                    only_bill_date : new Date(),
                    bill_date_kd : '',
                    bill_date_dd : '',
                    bill_date_kdtzs : '',
                    bill_date_fh: '',
                    contract_id : '',
                    delivery_date: '',
                    kick_back : '',
                    total_theoretical_weight: '',
                    bill_id_dd: '',
                    bill_id_kd : '',
                    bill_id_kdtzs : '',
                    bill_id_fh : '',
                    bill_id: '',
                    settlement_days: '',
                    customer: '',
                    company_uuid : '',//companyUUID
                    sales_man : '',
                    billing_company_name: '',
                    billing_company_id: '',
                    remark_warehouse_bill: '',
                    registrar: '',
                    total_amount: '',
                    balance: '',//未结清余额
                    practical_total_price : '',//合计实收
                    total_number: '',
                    actual_sales_amount_bill: '',//销售开单单据合计 实际销售金额
                    is_ticket: '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '销售开单',
                    creat_date_warehouse_bill : '',
                    handled_by: '',
                    consignee: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    amount_of_product : '',
                    cost_of_the_unit : '',
                    total_weighing_heavy : '',
                    cn_state_man : '',
                    cn_state_man_date: '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    taxes_fees : '',//产品税金
                    procedure_fees : '',//手续费
                    procedure_fees_diy: false,//手续费Diy
                    pounds_fees_diy: false,//磅费Diy
                    wind_cut_fees_diy: false,//风割费Diy
                    loading_car_fees_diy: false,//装车费Diy
                    transfer_fees_diy: false,//中转费Diy
                    freight_fees_diy: false,//运费Diy
                    other_freight_fees_diy: false,//外省运费Diy
                    naked_price_total: '',//销售开单 合计裸价
                    taxes_fees_second_string : 0,//后补税金
                    is_contract_fees: 0,//是包费用
                    all_fees: '',//合计杂费(磅费、风割费...)
                    voucher_man: '',//会计生成凭证
                    voucher_man_date: '',//会计生成凭证日期
                    is_cancel: false,//会计生成凭证日期
                    other_tax: '',//杂费税率
                    other_tax_money : '',//杂费税金
               },
           }
       },
        methods: {
            //客户选择修改，自动设置对应业务员
            customerChangeEvent(item){
                //console.log('item',item);
                this.formData.customer = item.customer_name;
                this.formData.company_uuid = item.company_uuid;
                this.customersFormId = item.id;
                //this.formData.sales_man = item.sales_man;
            },
            //隐藏列
            hideColumnEvent() {
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('remark_stock'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('kdcc_state_man'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('total_count'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('warehouse4'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('warehouse5'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('warehouse1'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('warehouse2'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('warehouse3'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('warehouse9'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('warehouse7'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('batch_number'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('theoretical_weight_single'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('stock_bill_date_dd'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('actual_thickness'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('polish_roll'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('theoretical_weight'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('category'))
                this.$refs.xGrid.$refs.baseGird.hideColumn(this.$refs.xGrid.$refs.baseGird.getColumnByField('unit'))
           },

            //出仓审核
            outBatch(b) {
                if (this.$_isGridDataChange(this.$refs['xGrid']) || this.isDataChanage) {
                    this.$message({
                        showClose: true,
                        message: '当前数据已被修改，请先保存，再操作！',
                        type: 'error'
                   });
               } else {
                    this.$axios({
                        method: 'post',
                        url: '/admin/transferSlip/outBatch',
                        data: {
                            bill_id_kd : this.formData.bill_id_kd,
                            b: b
                       }
                   }).then((response) => {         //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if (response.status === 200 && response.data.state == 'ok') {
                            this.$message({
                                showClose: true,
                                message: '批量设置成功',
                                type: 'success'
                           });
                            this.searchWarehouseBill()
                       }
                   }).catch((error) => {
                        console.log(error)
                   });
               }

           },
            //后补税金
            taxesFeesSecondStringEvent() {
                //console.log('taxesFeesSecondStringEvent');
                if (!this.isBlankVue(this.formData.taxes_fees_second_string) && (!this.isBlankVue(this.formData.tax_tate_warehouse_bill) || !this.isBlankVue(this.formData.other_tax))) {
                    /*setTimeout(() => {

                   }, 500)*/
                    // this.$XModal.alert({content: '税率已存在，不能使用后补税金！', maskClosable: true})
                    this.$XModal.alert('税率已存在，不能使用后补税金！', '不要皮哟').then(() => {
                        //const $grid = this.$refs.xGrid
                        this.formData.taxes_fees_second_string = 0;
                        this.$_sumTopSome()
                   })
               } else {
                    this.formData.taxes_fees = this.formData.taxes_fees_second_string;
                    this.$_sumTopSome()
               }

           },

            searchWarehouseBillByDd() {
                this.$axios({
                    method: 'post',
                    url: '/admin/salesOrdersSingle/search',
                    params: {
                        bill_id_dd : this.formData.bill_id_dd,
                        is_transfer_slip : true
                    },
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.warehouseBill;

                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        //遍历处理 清空 stock id 用于 开单通知书保存
                        for (let i = 0; i < this.tableDataMain.length; i++) {
                            //清空实厚
                            this.tableDataMain[i].dd_stock_id = this.tableDataMain[i].id;
                            this.tableDataMain[i].id = '';
                            this.tableDataMain[i].batch_number = '';
                            this.tableDataMain[i].single_count = this.$XEUtils.subtract(this.tableDataMain[i].single_count,this.tableDataMain[i].dd_is_ok_count);
                            if (this.tableDataMain[i].single_count == 0){
                                this.tableDataMain[i].single_count = '';
                            }
                        }
                        //清空bill Id
                        this.formData.id = '';
                        //开单日期今天
                        this.formData.only_bill_date = new Date();
                        //记录员
                        this.formData.registrar = this.$store.getters.getLoginAccount.nick_name
                        if (this.formData.bill_id_kd != null && this.formData.bill_id_kd != '') {
                            //先进行重新跳转
                            this.$router.push('/transferSlip?bill_id_kd='+this.formData.bill_id_kd);
                        }
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            searchWarehouseBill() {
                //console.log(this.formData.bill_id_kd)
                if (!this.isBlankVue(this.formData.bill_id_kd)) {
                    this.isEdit = true;
                    const loading = this.$loading({
                        lock: true,
                        text: '饮茶先啦，数据处理中，请耐心等待!',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                   });
                    this.$axios({
                        method: 'post',
                        url: '/admin/transferSlip/search',
                        params: {
                            bill_id_kd : this.formData.bill_id_kd,
                       },
                   }).then((response) => {         //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        loading.close();
                        this.titleList = [];
                        this.titleListSize = 0;
                        if (response.status === 200 && response.data.state == 'ok') {
                            this.formData = response.data.warehouseBill;
                            this.tableDataMain = this.$_createRowData(response.data.stocks);
                            //判断是否开了收款单
                            if (this.formData.total_amount == this.formData.balance) {
                                this.isEdit = false;
                                //不为空，说明是：已审核
                                if (!this.isBlankVue(this.formData.cn_state_man)) {
                                    //this.isEdit = true;
                                    this.titleList.push('出纳已审核');
                                    this.titleListSize = this.titleListSize + 1;
                                    //this.$XModal.alert({content: '出纳已审核，不能修改！请联系出纳处理！', maskClosable: true})
                               }
                           } else {
                                this.isEdit = false;
                                //this.isEdit = true;
                                this.titleList.push('已开《收款单》');
                                this.titleListSize = this.titleListSize + 1;
                                //this.$XModal.alert({content: '已开“收款单”不能修改，请联系出纳取消“收款单”的金额！', maskClosable: true})
                           }
                            for (var t in this.tableDataMain) {
                                if (!this.isBlankVue(this.tableDataMain[t].kdcc_state_man)) {
                                    //this.isEdit = true;
                                    this.titleList.push('已存在出仓审核');
                                    this.titleListSize = this.titleListSize + 1;
                                    //this.$XModal.alert({content: '已存在出仓审核，不能修改！请联系出仓审核人员反选处理！', maskClosable: true})
                                    break;
                               }
                           }
                            /*if (!this.isBlankVue(this.formData.invoice_id)) {
                                this.isEdit = true;
                                this.titleList.push('已开发票');
                           }*/
                            if (!this.isBlankVue(this.formData.has_invoice)) {
                                //this.isEdit = true;
                                this.titleList.push('已开发票');
                           }

                            if (!this.isBlankVue(this.formData.voucher_id)) {
                                this.isEdit = true;
                                this.titleList.push('已生成《记账凭证》');
                                this.titleListSize = this.titleListSize + 1;
                           }
                            if (!this.isBlankVue(this.formData.recordInventory) && !this.isBlankVue(this.formData.recordInventory.is_over)) {
                                this.isEdit = true;
                                this.titleList.push('库存已结转');
                                this.titleListSize = this.titleListSize + 1;
                           }
                       } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                           });
                            return
                       }
                   }).catch((error) => {
                        loading.close();
                        console.log(error)
                   });
               }

           },
            searchWarehouseBillByKdTzs() {
                this.$axios({
                    method: 'post',
                    url: '/admin/transferSlipInform/search',
                    params: {
                        bill_id_kdtzs : this.formData.bill_id_kdtzs,
                        is_transfer_slip : true
                    },
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        //遍历处理 清空 stock id 用于 销售开单保存
                        for (let i = 0; i < this.tableDataMain.length; i++) {
                            //清空实厚
                            this.tableDataMain[i].id = '';
                            this.tableDataMain[i].batch_number = '';
                            this.tableDataMain[i].actual_thickness = '';
                       }
                        //清空bill Id
                        this.formData.id = '';

                        //remarkWarehouseBill 开单通知书 税率 过到备注
                        //this.formData.remark_warehouse_bill = this.formData.tax_tate_warehouse_bill;
                        //税率取消关联赋值
                        //this.formData.tax_tate_warehouse_bill = '';
                        //开单日期今天
                        this.formData.only_bill_date = new Date();
                        //记录员
                        this.formData.registrar = this.$store.getters.getLoginAccount.nick_name

                        //console.log(this.formData.bill_id_kd)
                        //console.log(this.$store.getters.getLoginAccount.billing_company_id)
                        //console.log(this.$store.getters.getLoginAccount.billing_company_name)
                        if (this.isBlankVue(this.formData.bill_id_kd)){
                            this.formData.billing_company_id = this.$store.getters.getLoginAccount.billing_company_id;
                            this.formData.billing_company_name = this.$store.getters.getLoginAccount.billing_company_name;
                            //console.log(this.$store.getters.getLoginAccount.billing_company_name)
                            //console.log(this.formData.billing_company_name)
                       }
                        if (this.formData.bill_id_kd != null && this.formData.bill_id_kd != '') {
                            //先进行重新跳转
                            this.$router.push('/transferSlip?bill_id_kd='+this.formData.bill_id_kd);
                        }
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            PrintTransferSlip() {
                if (this.formData.bill_id_kd != null && this.formData.bill_id_kd != '') {
                    if (this.$_isGridDataChange(this.$refs['xGrid']) || this.isDataChanage) {
                        this.$message({
                            showClose: true,
                            message: '当前数据已被修改，请先保存，再打印！',
                            type: 'error'
                       });
                   } else {
                        this.$router.push({path: "/printTransferSlip", query: {bill_id_kd : this.formData.bill_id_kd}})
                   }
               } else {
                    this.$message({
                        showClose: true,
                        message: '打印前，请先保存单据，单号不能为空！',
                        type: 'error'
                   });
               }
           },
            createBillByPrintShippingAdvice() {
                if (this.$_isGridDataChange(this.$refs['xGrid']) || this.isDataChanage) {
                    this.$message({
                        showClose: true,
                        message: '当前数据已被修改，请先保存，再生成！',
                        type: 'error'
                   });
               } else if (this.formData.bill_id_fh != '' && this.formData.bill_id_fh != null) {
                    this.$router.push({path: "/shippingAdvice", query: {bill_id_fh : this.formData.bill_id_fh}})
               } else if (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '') {
                    this.$router.push({path: "/shippingAdvice", query: {bill_id_kdtzs : this.formData.bill_id_kdtzs}})
               } else {
                    /*this.$message({
                        showClose: true,
                        message: '生成《销售开单》前，请先保存单据，单号不能为空！',
                        type: 'error'
                   });*/
               }
           },
            getUpdateEvent() {
                let updateRecords = this.$refs.xGrid.getUpdateRecords()
                return updateRecords.length
           },
            async handledByEvent(){
                if (!this.isBlankVue(this.formData.bill_id_kd)){
                    this.saveOrUpdate();
               }
           },
            checkRemarkWarehouseBill(){
                if(this.formData.remark_warehouse_bill.indexOf("方") > -1 && this.formData.remark_warehouse_bill.indexOf("板") >  -1){
                    this.$message({
                        showClose: true,
                        message: '销售备注，不能同时写“方”、“板”！',
                        type: 'error'
                    });
                    this.formData.remark_warehouse_bill = this.formData.remark_warehouse_bill.replace("板","");
                }
            },
            saveOrUpdate() {
                this.loading = true
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.isDataChanage = false;
                let url = '';
                if (this.isAddBill) {
                    url = '/admin/transferSlip/saveVue';
               } else {
                    url = '/admin/transferSlip/updateVue';
               }
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        formData: this.formData,
                        tableDataMain: this.$refs.xGrid.$refs.baseGird.getTableData().fullData,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    this.loading = false
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        this.$router.push({path: "/transferSlip", query: {bill_id_kd : this.formData.bill_id_kd}})
                        this.searchWarehouseBill()
                   } else {
                        this.loading = false
                        if (response.data.outLock == true){
                            this.$confirm(response.data.msg, '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.$message({
                                    type: 'success',
                                    message: '已解除“销售订单规格ID”'
                                });
                                this.tableDataMain[response.data.rowIndex].dd_stock_id = ''
                            })/*.catch(() => {
                                this.$message({
                                    type: 'info',
                                    message: '点取消'
                                });
                            })*/;
                        }else if(response.data.showCompanyModel == true){
                            this.$message.error(response.data.msg);
                            this.isShowCompanyModel = true;
                            this.customersErrorMsg = response.data.msg;
                            this.customersFormId = response.data.id;
                        }else{
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                            });
                        }
                        return
                   }
               }).catch((error) => {
                    console.log(error)
                    this.loading = false
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
               });
           },
            onSubmit() {
                //保存之前，所有公式数据再计算一次
                this.$_sumAllFees();
                this.$_setOtherTaxMoney();
                this.$_getFeesEveryRow();
                this.$_singleCountEventEvery();
                this.saveOrUpdate();
                /*this.saveOrUpdate().then(() => {
                    //this.searchWarehouseBill();
               });*/
           },
       }, computed: {
            // 计算属性的 getter
            billName: function () {
                // `this` 指向 vm 实例
                if (this.formData.clearing_form === '欠款') {
                    return '当前：销售开单：欠款单';
               } else {
                    return '当前：销售开单：调拨单';
               }
           },
            isAddBill: function () {
                if (this.formData.bill_id_kd == null || this.formData.bill_id_kd == '') {
                    return true;
               } else {
                    return false;
               }
           }
       },
        created() {
            this.formData.bill_id_kd = this.$route.query.bill_id_kd;
            this.formData.bill_id_kdtzs = this.$route.query.bill_id_kdtzs;
            this.formData.bill_id_dd = this.$route.query.bill_id_dd;
            if (this.$route.query.BackOrGoId != null && this.$route.query.BackOrGoId != '') {
                this.warehouseBackOrGo.id = this.$route.query.BackOrGoId
                this.warehouseBackOrGo.name = this.$route.query.BackOrGoName
            }
            this.$_searchCustomerList('');
            this.$_searchCategoryList('');
            this.$_searchUnitList('');
            this.$_searcWarehouseList('');
            this.$_getAccountListByRoleId('10');//业务员
            this.$_getAccountListByRoleId('14');//开单员
            this.$_getAccountListByRoleId('16');//经手人
            this.$_getBillingCompanySettingList('');
            this.$_getClearingFormList('');
            this.$_getOtherExpensesList('');
            if (this.formData.bill_id_dd != null && this.formData.bill_id_dd != '') {
                this.searchWarehouseBillByDd();

            } else if (this.formData.bill_id_kd != null && this.formData.bill_id_kd != '') {
                this.searchWarehouseBill();
            } else if (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '') {
                this.searchWarehouseBillByKdTzs();
            } else {
                var list = [];
                this.tableDataMain = this.$_createRowData(list);
            }
            //如果记录员(业务员)为空，则自动填写当前账户名字
            if (this.formData.registrar == null || this.formData.registrar == '') {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name
           }
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                if (this.isBlankVue(this.formData.bill_id_kd)){
                    this.formData.billing_company_id = this.$store.getters.getLoginAccount.billing_company_id;
                    this.formData.billing_company_name = this.$store.getters.getLoginAccount.billing_company_name;
                }

                //如果为空，设置默认值
                /*if(this.isBlankVue(this.formData.billing_company_name)){
                    this.$_findDefault();
               }*/
           })
       }
   };


</script>

<style scoped>
    .input-with-select .el-input .el-input--mini .el-input-group .el-input-group--prepend .el-input-group__prepend {
        width: 80px !important;
   }

    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 128px !important;
   }

    .el-form-item__content .el-input-group {
        vertical-align: initial;
   }

    /deep/ .vxe-input--number-suffix {
        display: none;
   }


    /deep/ .vxe-input--suffix {
        height: auto;
   }

    /deep/ .vxe-input .vxe-input--suffix {
        width: auto;
   }

    /deep/ .vxe-input.is--controls.type--number .vxe-input--suffix {
        right: 0.2em;
   }

    /deep/ #otherExpenses {
        background-color: cornsilk
   }

    /deep/ .vxe-input.is--suffix.is--controls.type--number .vxe-input--inner {
        padding-right: 1.2em;
   }

    /deep/ .doSuffix > .el-form-item__label {
        padding: initial;
   }

    /deep/ .el-input-group__append{
        padding:initial;
    }
</style>
